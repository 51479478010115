/* eslint-disable */
import axios from 'axios';

export default class AppVersionService {
  constructor() {}


  async GetAppVersion(appCode, typeOS) {
    return await axios.get(`${process.env.VUE_APP_API_URL}/security/app-version?appCode=${appCode}&typeOS=${typeOS}`)
        .then(response => response).catch(error => error);
  }
}
