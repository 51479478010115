<template>
  <div style="height: 100vh; margin: 0 auto;">
    <v-row style="height: 100vh;" v-show="!isLoadedIframe && user" class="overline">
      <v-col cols="12" align-self="center" align="center">Carregando credenciais...</v-col>
    </v-row>
    <iframe v-show="isLoadedIframe" v-if="loginURL" :src="`${loginURL}`" height="100%" scrolling="auto" width="100%" frameborder="0" @load="onLoadIframeFinished"/>

    <v-dialog v-model="dialog.show" persistent max-width="600">
      <v-card :style="dialog.style" rounded="md" class="overflow-y-auto">
        <v-card-title style="word-break: break-word;">
          {{ appVersion && appVersion.required ? 'Atualização obrigatória disponível' : 'Atualização disponível' }}
        </v-card-title>

        <v-card-text class="text-justify">
          <p>{{dialog.message}}</p>
          <v-alert class="mt-5 pa-2" color="primary" style="color: white;" rounded v-show="dialog.showAlert">
            Link indisponível. Verifique a atualização disponível na loja ou no site do aplicativo.
          </v-alert>
        </v-card-text>

        <v-card-actions class="px-5 pb-5 justify-center">
          <v-btn color="primary" @click.stop="onClickUpdate">
            Atualizar
          </v-btn>
          <v-btn v-if="appVersion && !appVersion.required" color="primary" text @click="onClickCancel()">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import store from "@/store/store";
  import { mapMutations } from "vuex";
  import { Capacitor } from "@capacitor/core";
  import { App } from '@capacitor/app';
  import { NativeBiometric } from "capacitor-native-biometric";
  import AppVersionService from "@/services/security/AppVersionService";

  export default {
    name: 'Login',
    data: () => ({
      user: null,
      loginURL: null,
      isLoadedIframe: false,
      sessionStorageBiometricKey: 'BIOMETRIC',
      countRetry: 3,
      biometricInfo: {
        title: 'Acessar o Eu Protegido',
        reason: 'Autenticação rápida e segura',
        subtitle: 'Autenticação por biometria',
        description: 'Confirme para continuar',
      },
      appVersionRelease: 118,
      appCode: 'eu_protegido',
      typeOS: 'WEB',
      refreshing: false,
      registration: null,
      appVersion: null,
      dialog: {
        message: '',
        show: false,
        showAlert: false,
        colorDefault: 'primary',
        style: {
          color: '#532E88',
          backgroundColor: '#EEEDF7',
          height: '50vh'
        },
        timeoutAlert: 10000,
      },
    }),
    computed: {
    },
    methods: {
      ...mapMutations(["showmsg", "loading"]),
      async showUpdateDialogComponent() {
        const path = this.$route.path;

        if (path && path == '/login') {
          this._appVersionService = new AppVersionService();

          if (Capacitor.isNativePlatform()) {
            if (Capacitor.getPlatform() === 'ios') {
              this.typeOS = 'IOS';
            } else {
              this.typeOS = 'ANDROID';
            }
          }

          await this._appVersionService.GetAppVersion(this.appCode, this.typeOS)
              .then((response) => {
                if(response && response.data) {
                  this.appVersion = response.data;

                  if(this.appVersion.version) {
                    this.appVersion.version = this.appVersion.version.replaceAll('.', '');
                    if(this.appVersionRelease < parseInt(this.appVersion.version)) {
                      this.dialog.message = this.appVersion.description;
                      this.dialog.show = true;
                    }
                  }
                }
              });
        }
      },
      onClickUpdate() {
        if(this.typeOS === 'ANDROID' && this.appVersion && this.appVersion.link && this.appVersion.link.linkGoogle) {
          this.openUrl(this.appVersion.link.linkGoogle);
          App.exitApp();
        } else if (this.typeOS === 'IOS' && this.appVersion && this.appVersion.link && this.appVersion.link.linkApple) {
          this.openUrl(this.appVersion.link.linkApple);
          App.exitApp();
        } else if (this.typeOS === 'WEB') {
          this.refreshWebApp();
        } else {
          this.dialog.showAlert = true;
          setTimeout(() => {
            this.dialog.showAlert = false;
          }, this.dialog.timeoutAlert);
        }
      },
      refreshWebApp() {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          if(registrations && registrations.length) {
            registrations.forEach(registration => {
              if(registration && registration.active && registration.active.scriptURL === `${process.env.VUE_APP_EU_PROTEGIDO_URL}/service-worker.js`) {
                registration.unregister();
              }
            })
          }
        }).finally(() => {
          this.dialog.show = false;
          window.location.reload();
        });
      },
      openUrl (linkOS) {
        let linkToOpen = '';
        try{
          linkToOpen = this.$util.getUrlApp(JSON.parse(linkOS));
        } catch(e){
          if (this.$util.isIosMobile()) {
            linkToOpen = linkOS;
          } else {
            linkToOpen = linkOS;
          }
        } finally {
          window.location.href = linkToOpen;
        }
      },
      onClickCancel() {
        if(this.appVersion && !this.appVersion.required) {
          this.dialog.show = false;
        }
      },
      onLoadIframeFinished() {
        this.isLoadedIframe = true;
      },
      initAccountLoginURL() {
        let userEmail = null;
        let userPassword = null;
        let userParams = '';

        if(this.user) {
          userEmail = this.user.email;
          userPassword = this.user.password;
          userParams = `&userEmail=${userEmail}&userPassword=${userPassword}`;
        }

        this.loginURL = `${process.env.VUE_APP_ACCOUNT_URL}${process.env.VUE_APP_ACCOUNT_LOGIN_PATH}?callback=${process.env.VUE_APP_EU_PROTEGIDO_URL}${process.env.VUE_APP_ACCOUNT_LOGIN_CALLBACK_PATH}&userRole=${process.env.VUE_APP_ACCOUNT_USER_ROLE}&appCode=${process.env.VUE_APP_ACCOUNT_APP_CODE}&appVersion=${process.env.VUE_APP_BUILD_VERSION}${userParams}`;
      },
      initBiometricNative() {
        const isNative = Capacitor.isNativePlatform();
        const serverOrigin = window.location.origin;

        NativeBiometric.isAvailable()
          .then(() => {

            if(isNative){
              NativeBiometric.getCredentials({
                server: serverOrigin,
              }).then(() => {
                if(isNative) {
                  this.createBiometricNative();
                }
              })
              .catch(() => {
                this.initAccountLoginURL();
              });
            }
          })
          .catch((error) => {
            if(isNative){
              alert('error: ' + JSON.stringify(error));
            }
            this.initAccountLoginURL();
          });
      },
      createBiometricNative() {
        const isNative = Capacitor.isNativePlatform();
        const serverOrigin = window.location.origin;

        NativeBiometric.isAvailable()
            .then(() => {

              if(isNative){
                NativeBiometric.getCredentials({
                  server: serverOrigin,
                }).then((result) => {

                  NativeBiometric.verifyIdentity({
                    title: this.biometricInfo.title,
                    reason: this.biometricInfo.reason,
                    subtitle: this.biometricInfo.subtitle,
                    description: this.biometricInfo.description,
                  }).then(() => {

                    if(isNative) {
                      this.user = {
                        email: result.username,
                        password: result.password,
                      }
                      this.initAccountLoginURL();
                    }

                  }).catch((error) => {
                    if(isNative) {
                      if(error && error.code === '0') {
                        this.loginBiometricCanceled();
                      } else if (error && error.code === '10') {
                        this.validateRetryLoginBiometric();
                      } else {
                        this.loginBiometricCanceled();
                      }
                    }
                    this.callNormalLoginRetry();
                  });

                })
                .catch(() => {
                  this.initAccountLoginURL();
                });
              }
            })
            .catch((error) => {
              if(isNative){
                alert('error: ' + JSON.stringify(error));
                this.loginBiometricCanceled();
              }
              this.initAccountLoginURL();
            });
      },
      loginBiometricCanceled() {
        this.initAccountLoginURL();
      },
      callNormalLoginRetry() {
        if(this.countRetry <= 0) {
          this.initAccountLoginURL();
        }
      },
      validateRetryLoginBiometric() {
        this.countRetry--;
        if(this.countRetry > 0) {
          this.biometricInfo.subtitle = 'Não foi possível autenticar';
          this.biometricInfo.description = `Você tem mais ${this.countRetry} tentativa(s)`;
          this.initBiometricNative();
        } else {
          this.loginBiometricCanceled();
        }
      }
    },
    async created() {
      sessionStorage.removeItem(this.sessionStorageBiometricKey);

      this.initBiometricNative();

      setTimeout(() => {
        this.loading(false);
      }, 2000);

      if(this.$route.params && this.$route.params.logout){
        this.$router.replace({params: {}})
        document.location.reload(true);
      }

      await this.showUpdateDialogComponent();
    },
    async beforeRouteEnter(to, from, next) {
      const logged = await store.dispatch('checkAuthentication');
      if (logged) {
        next('/');
      } else {
        next();
      }
    }
  };
</script>

<style scoped>
iframe{
  overflow:hidden !important;
  border: none;
}
</style>
